







































import { Component, Vue, Prop } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({ components: {} })
export default class ConfiguracionFichaOpciones extends Vue {
  @Prop() id_nutricionista!: number;
  public dialog = false;
  public counterclickOp: any[] = [];
  created() {}

  public get allbuttons() {
    return [
      {
        id: 1,
        titulo: "Ficha",
        descripcion: "Datos de la clinica y nutricionista",
        icono: "fad fa-user-alt ",
        routername: RouterNames.configuracion_ficha
      },
      {
        id: 2,
        titulo: "Preguntas",
        descripcion: "Preguntas a realizar en las consultas",
        icono: "fad fa-map-marker-question",
        routername: RouterNames.configuracion_preguntas
      },
      {
        id: 3,
        titulo: "Mediciones",
        descripcion: "Mediciones de uso avanzado",
        icono: "fad fa-adjust",
        routername: RouterNames.mediciones_avanzadas
      }
    ];
  }

  public showPopup(): boolean {
    const result = this.counterclickOp.filter(
      item => this.$route.name === item.routername
    );

    return result.length >= 3;
  }
  public clickbutton(item: any) {
    if (this.$route.name === item.routername) {
      this.counterclickOp.push(item);
      this.dialog = this.showPopup();
      return;
    }
    this.$router.push({
      name: item.routername
    });
  }
}
